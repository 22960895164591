body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Swift Neue LT W01,Georgia,Lucida Bright,serif';
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.fullscreen > main { top: 7.5%;
  left: 0;
  right: 0;
  margin-bottom: 7.5%;
  background: rgb(220, 220, 220);
  position: absolute;
}
html, body, #app, #app>div {
  height: 100%
}
.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.ant-input-group-addon:last-child {
  border: 0;
  padding: 0;
  background-color: transparent;
}

/* Track
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}


/* width */
::-webkit-scrollbar {
  width: 7.5px;
  height: 10px;
  /* border-radius: .7em; */
  border-radius: 10px;

  /* scrollbar-color: var(--thumbBG) var(--scrollbarBG) */
} */
*********************************************
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;

  background: tra;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
    background-color: darkgrey;

  /* outline: 1px solid slategrey; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #555;
}
*********************************************
ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;  
}